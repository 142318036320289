<template lang="pug">
.content
  layoutContainer
    .layout
      <div v-if="SearchInput" class="SearchInput"> {{ SearchInput }}
        <i @click="$store.commit('ChangeSearchInput', '')" :class="'el-icon-error close-icon'"></i>
      </div>
      checkRadioGroupNew(title="服务分类"
        :list="service_list.base"
        :activeItem="service_list.findActiveParent(queryParams.businessId)"
        :selectCategoryName="service_list.findCheckedNameById(queryParams.businessId)"
        :activeIndex.sync="queryParams.businessId"
        keyname="name"
      )
      checkRadioGroupNew(title="所在地区"
        :list="area_list.base"
        :activeItem="area_list.findActiveParent(queryParams.provinceId)"
        :selectCategoryName="area_list.findCheckedNameById(queryParams.provinceId)"
        :activeIndex.sync="queryParams.provinceId" 
        keyname="name" 
      )
    .current-Type-Sum
      .sum(v-if="total !== 0") 
        span {{ currentBusiness && currentBusiness.name }}&nbsp;共
        span {{total}}
        span 服务
    .service
      ul
        li.list(v-for="(item, index) in serviceList" :key="`${index}${item.id}`")
          img.service-avatar(:src="item.companyLogoUrl" @click="gotoCompanyDetailPage(item.companyId)" draggle="false")
          .service-detail
            .service-name {{item.serviceName}}
            .service-businessName(@click="gotoCompanyDetailPage(item.companyId)") /{{item.companyName}}
            img.service-collect(@click="handelCollect(item)" :src="item.hasCollection ? require('@/assets/start-comment.png') : require('@/assets/start-no-collect.png')" draggle="false")
          .web-side-btn(v-show="item.hasWebSiteUrl" @click="goWebSite(item)") 官网
      .empty-img
        img.empty(src="./../../assets/listempty.png" v-if="total === 0 && loaded" )

      .left-column
        .publish-options
          .publish-product_btn(@click="publish") 新增服务 
          .publish-type_list(
            v-for="(item) in publishInfoList" 
            :class="{selected: currentUserSelectMine === item.val}"
            @click="setPublishType(item.val)"
          ) {{item.name}}
        .hot-service
          .title 热门
          ul
            li(
              v-for="item in hotService",
              :key="item.id"
              @click="chooseHotProduct(item.id)"
            )
              .hot-service-name(:style="{color: item.color, borderColor: item.color}") {{item.name}}
        .current-product(v-if="queryParams.businessId && !currentUserSelectHot && currentBusiness && currentBusiness.introduction")
          el-tooltip(placement="bottom" trigger="hover" :disabled="!(currentBusiness && currentBusiness.introduction)" effect="light" popper-class="item")
            span(slot="content" style="max-width: 300px;") {{ currentBusiness && currentBusiness.introduction }}
            img(src="../../assets/wenhao.png" draggable="false")
          span 什么是{{ currentBusiness && currentBusiness.name }}
    el-pagination.pagination(
      v-if="total !== 0"
      background
      layout="prev, pager, next"
      :current-page.sync="page"
      :page-size="size"
      :total="total"
      :page-sizes="[10, 20, 30, 40, 50, 100]"
      @current-change="currentChange"
    )
  vip(:showVipPage="showVipPage" @dialogClose="dialogClose")
</template>
<script>
import vip from '../../components/vip/index.vue'
import { mapState } from 'vuex'
import {
  regionListApi,
  hotProductListApi,
  collectApi,
  cancelCollectApi,
  getCompanyInfoByAdminApi,
  checkVip,
  checkAuth
} from '@/api/product'
import { serveListApi } from '@/api/serve.js'
import { getBusinessListApi } from '@/api/index.js'
import { getVipInfo } from '@/api/company.js'
import { checkedDeepFormat } from '../../utils/findQueryName'

export default {
  name: 'Product',
  components: { vip },
  data() {
    return {
      page: 1,
      total: 0,
      size: 12,
      loaded: false,
      hasEffected: false, // 是否是会员
      isManager: false, //是否是管理员
      isOperator: false, // 是否是运营人员
      currentUserSelectMine: '', // 右侧选中
      currentUserSelectHot: '', // 选中热门
      showVipPage: false, // 是否显示vip弹窗
      serviceList: [],
      hotService: [],
      serviceTypeList: [],
      regionList: [],
      publishInfoList: [
        {
          name: '我的服务',
          val: 1
        },
        {
          name: '我的收藏',
          val: 2
        }
      ],
      queryParams: {
        businessId: '', //业务类型
        provinceId: '', //所在地区id
        name: '', // 产品名称模糊查询
        page: 1,
        size: 12
      }
    }
  },
  created() {
    // 服务分类列表
    getBusinessListApi({ type: '服务' }).then(res => {
      this.serviceTypeList = res.data.serviceList
      this.getServiceList()
      this.getHotServiceList()
    })
    // 所在地区列表
    regionListApi().then(res => {
      this.regionList = res.data
    })
    if (this.UserInfo && this.UserInfo.id) {
      // 是否是运营
      checkAuth({ and: true, authList: [1] }).then(res => {
        this.isOperator = res.code === 200
      })
    } else {
      this.isOperator = false
    }

    // 首页跳转，设置默认我的服务
    if (this.$route.query.publishType == 1) {
      this.setPublishType(1)
    }
  },
  watch: {
    queryParams: {
      deep: true,
      handler() {
        if (this.checking) {
          this.checking = false
          return
        }
        this.getServiceList()
      }
    },
    UserInfo: {
      deep: true,
      immediate: true,
      handler() {
        if (this.UserInfo && this.UserInfo.id) {
          // 是否是管理员
          getCompanyInfoByAdminApi().then(res => {
            this.isManager = res.data === null ? false : true
          })

          // 是否是会员
          checkVip().then(res => {
            this.hasEffected = res.data?.hasEffected || false
          })
        }
      }
    },
    SearchInput(val) {
      this.queryParams.name = val
      this.currentChange(1)
    },
    ['$route.query']: {
      immediate: true,
      handler() {
        const query = this.$route.query
        this.queryParams.businessId = query.business_id ? query.business_id : ''
        this.queryParams.provinceId = query.area_id ? query.area_id : ''
      }
    },
  },
  computed: {
    ...mapState(['UserInfo', 'SearchInput']),
    currentBusiness() {
      return this.serviceTypeList.filter(item => item.id === this.queryParams.businessId)[0]
    },
    service_list() {
      return checkedDeepFormat(this.serviceTypeList) || {}
    },
    area_list() {
      return checkedDeepFormat(this.regionList) || {}
    },
  },
  methods: {
    dialogClose(value) {
      this.showVipPage = false
      if (value) {
        this.getServiceList()
      }
    },
    getServiceList() {
      this.loaded = false
      const params = Object.assign(this.queryParams, {
        myCollection: this.currentUserSelectMine === 2,
        myService: this.currentUserSelectMine === 1
      })
      serveListApi(params).then(res => {
        this.serviceList = res.data ? (res.data.records ? res.data.records : []) : []
        this.total = res.data ? res.data.total : 0
        this.loaded = true
      })
    },
    currentChange(page) {
      this.queryParams.page = page
      this.getServiceList()
    },
    getRandomColor() {
      const color = {
        1: 'rgb(30, 128, 128)',
        2: 'rgb(51, 169, 169)',
        3: 'rgb(110, 218, 218)'
      }

      const randomNum = Math.floor(Math.random() * 2) + 1
      return color[randomNum + '']
    },
    // 热门
    getHotServiceList() {
      hotProductListApi({ type: '服务' }).then(res => {
        this.hotService = res.data

        this.hotService.forEach(item => {
          item.color = this.getRandomColor()
        })
      })
    },
    chooseHotProduct(id) {
      // if (this.currentUserSelectHot === id) {
      //   this.checking = false
      //   this.currentUserSelectHot = ''
      // } else {
      //   this.checking = true
      //   this.currentUserSelectHot = id
      // }
      this.queryParams.businessId = id
    },
    setPublishType(type) {
      if (!this.UserInfo || !this.UserInfo.id) {
        this.$router.push('/login')
        return
      }
      if (this.currentUserSelectMine === type) {
        this.checking = false
        this.currentUserSelectMine = ''
      } else {
        this.checking = true
        this.currentUserSelectMine = type
      }
      this.resetQueryParams()
      this.getServiceList()
    },
    resetQueryParams() {
      const queryParams = {
        businessId: '', //业务类型
        provinceId: '', //所在地区id
        name: '', // 产品名称模糊查询
        page: 1,
        size: 12
      }
      this.$set(this, 'queryParams', queryParams)
    },
    // 新增产品
    publish() {
      if (!this.UserInfo || !this.UserInfo.id) {
        this.$router.push('/login')
        return
      }
      if (!this.isManager && !this.isOperator) {
        this.$dialog.toast('需要先入驻公司')
        return
      }
      this.$router.push({
        path: '/serve/detail',
        query: {
          serveEdit: false
        }
      })
    },
    publishSuccess() {
      this.$message.success('发布成功')
      this.queryParams = {
        statusTypes: '',
        businessTypes: '',
        entrustPartTypes: '',
        cooperationWayTypes: ''
      }
      this.currentChange(1)
    },
    handelCollect(item) {
      if (!this.UserInfo || !this.UserInfo.id) {
        this.$router.push('/login')
        return
      }
      item.hasCollection = !item.hasCollection
      console.log('item ', item)
      if (item.hasCollection) {
        collectApi({ objectId: item.serviceId, objectType: '服务' }).then(() => {
          this.$dialog.toast('收藏成功')
        })
      } else {
        cancelCollectApi({ objectId: item.serviceId, objectType: '服务' }).then(() => {
          this.$dialog.toast('已取消收藏')
        })
      }
    },
    gotoCompanyDetailPage(id) {
      this.$router.push({
        path: `/companyDetail/${id}`
      })
    },
    goWebSite(item) {
      if (!this.UserInfo || !this.UserInfo.id) {
        this.$router.push('/login')
        return
      }
      if (!this.hasEffected && !this.isOperator) {
        this.showVipPage = true
        return
      } else {
        getVipInfo({ companyId: item.companyId }).then(res => {
          window.open(res.data.websiteUrl)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$radius: 8px;
$fontFamily: PingFangSC-Medium, PingFang SC;
$btnColor: #55cbc4;

.SearchInput {
  display: inline-block;
  margin-left: 160px;
  height: 36px;
  line-height: 36px;
  padding-left: 20px;
  padding-right: 20px;
  background: #5ad3cd;
  color: #fff;
  border-radius: 4px;
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;

  >i {
    position: absolute;
    color: #666666;
    font-size: 20px;
    right: -30px;
    top: 8px;
  }
}

.content {
  /deep/ .el-dialog {
    border-radius: 10px;
  }

  .layout {
    background-color: #fff;
    padding: 20px 0;
  }
}

.empty {
  width: 600px;
  display: block;
  margin: 80px auto;
}

.selected {
  border: 1px solid #5ad3cd !important;
  color: #5ad3cd !important;
}

.current-Type-Sum {
  height: 28px;
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  margin: 30px 0;

  span {
    display: inline-block;
    padding-left: 6px;

    &:nth-child(2) {
      color: rgba(255, 114, 76, 1);
    }
  }
}

.service {
  display: flex;
  justify-content: space-between;

  .list {
    position: relative;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    width: 432px;
    height: 80px;
    box-sizing: border-box;
    padding: 10px 0 10px 10px;
    background: #fff;
    margin-bottom: 20px;
    border-radius: 101px;
    position: relative;
    overflow: hidden;
    opacity: 0.8;

    &:nth-child(2n + 1) {
      margin-right: 16px;
    }

    .service-avatar {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin-right: 8px;
      object-fit: contain;
      cursor: pointer;
    }

    .web-side-btn {
      position: absolute;
      right: 30px;
      padding: 5px 8px;
      border-radius: 18px;
      border: 1px solid #ff724c;
      font-size: 18px;
      font-weight: 500;
      color: #ff724c;
      cursor: pointer;
    }

    .service-type {
      min-width: 90px;
      height: 40px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 40px;
      padding: 0 10px;
      text-align: center;
      border-radius: 0 30px 30px 0;
      background-color: #55cbc4;
      position: absolute;
      top: 20px;
      left: 0;
    }

    .service-detail {
      display: flex;
      align-items: center;
    }

    .service-name {
      display: inline-block;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      width: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .service-businessName {
      width: 130px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 500;
      color: #333333;
      font-size: 24px;
      margin-left: 4px;
      display: inline-block;
      cursor: pointer;
    }

    .service-collect {
      width: 26px;
      height: 26px;
      object-fit: contain;
      margin-left: 7px;
      cursor: pointer;
    }

    .service-introduction {
      font-size: 20px;
      font-weight: 400;
      color: #999999;
      line-height: 28px;
      width: 620px;

      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .company-info {
      position: absolute;
      right: 30px;
      top: 20px;
      text-align: right;

      .budget {
        height: 30px;
        font-size: 22px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 30px;
        margin-bottom: 10px;

        span {
          display: inline-block;
          margin-left: 10px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 30px;
          vertical-align: top;
        }
      }

      .customer-name {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
        margin-bottom: 6px;

        .location {
          width: 12px;
          display: inline-block;
          margin: 0 0 0 4px;
        }
      }

      .publish-time {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
      }
    }

    .service-status_info {
      margin-bottom: 20px;

      p {
        height: 26px;
        border: 1px solid #37c2bc;
        border-radius: 20px;
        padding: 0 19px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #37c2bc;
        line-height: 24px;
        margin-right: 12px;
        display: inline-block;
        vertical-align: top;
      }

      span {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 26px;
        vertical-align: top;
      }
    }

    .service-entrust_part {
      overflow: hidden;

      .part,
      .part-red {
        height: 22px;
        float: left;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 22px;
        margin-right: 10px;
        padding: 0 6px;
        border-radius: 2px;
        color: #5ad3cd;
        background-color: rgba(90, 211, 205, 0.1);
      }

      .part-red {
        background-color: rgba(255, 114, 76, 0.1);
        color: rgba(255, 114, 76, 1);
      }
    }
  }

  .left-column {
    width: 290px;
    margin-top: -68px;

    .publish-options,
    .hot-service {
      background: #fff;
      margin-bottom: 16px;
      box-sizing: border-box;
      border-radius: $radius;
      padding: 20px 15px 4px;

      .title {
        font-size: 18px;
        font-family: $fontFamily;
        font-weight: 500;
        color: #333333;
        line-height: 25px;
        margin-bottom: 15px;
      }

      ul {
        padding-top: 15px;
        border-top: 2px solid #dcdfe6;

        li {
          margin-bottom: 12px;
          display: inline-block;
          margin-right: 12px;
          cursor: pointer;

          .service-type {
            width: 20px;
            height: 20px;
            display: inline-block;
            margin-right: 10px;
            background-color: $btnColor;
            line-height: 20px;
            text-align: center;
            font-size: 14px;
            color: #fff;
            vertical-align: top;
          }

          .hot-service-name {
            display: inline-block;
            height: 32px;
            font-size: 16px;
            padding: 0 13px;
            border-radius: 18px;
            font-family: $fontFamily;
            font-weight: 500;
            color: #5ad3cd;
            line-height: 30px;
            display: inline-block;
            overflow: hidden;
            max-width: 110px;
            white-space: nowrap;
            text-overflow: ellipsis;
            vertical-align: top;
            border: 1px solid #5ad3cd;
          }

          span {
            height: 16px;
            display: inline-block;
            font-size: 10px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #5ad3cd;
            line-height: 13.5px;
            border: 1px solid $btnColor;
            padding: 0 5px;
            margin-left: 6px;
            margin-top: 2px;
            border-radius: 10px;
            vertical-align: top;
          }

          .service-budget {
            float: right;
            height: 20px;
            font-size: 14px;
            font-family: $fontFamily;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
          }
        }
      }
    }

    .current-product {
      width: 290px;
      height: 80px;
      padding-left: 20px;
      background: #ffffff;
      border-radius: $radius;
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      img {
        width: 28px;
        height: 28px;
        object-fit: contain;
        position: relative;
      }

      span:last-child {
        display: inline-block;
        margin-left: 10px;
      }
    }

    .publish-options {
      padding: 30px 15px 20px;
      overflow: hidden;

      .publish-product_btn {
        width: 260px;
        height: 50px;
        font-size: 18px;
        font-weight: 500;
        font-family: $fontFamily;
        color: #ffffff;
        line-height: 50px;
        background-color: $btnColor;
        text-align: center;
        border-radius: $radius;
        margin-bottom: 20px;
        cursor: pointer;
      }

      .publish-type_list {
        width: 123px;
        height: 48px;
        font-size: 18px;
        border: 1px solid #eeeeee;
        color: #999999;
        font-family: $fontFamily;
        font-weight: 500;
        line-height: 48px;
        text-align: center;
        margin-bottom: 20px;
        float: left;
        cursor: pointer;
        border-radius: $radius;

        &:nth-child(2n) {
          margin-right: 10px;
        }
      }
    }
  }
}

.pagination {
  text-align: center;
}
</style>
<style>
.item {
  max-width: 200px;
}
</style>
